import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Add this import for navigation

export const AuthContext = createContext();

const api = axios.create({
  baseURL: process.env.REACT_APP_RAPID_DATA,
});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(localStorage.getItem("token"));
  const navigate = useNavigate(); // Use navigate hook for redirecting

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await api.post("/api/v1/auth/validate_user", {
            token,
          });
          const isTokenValid = response.data;

          if (isTokenValid) {
            setAuth(token);
            // Redirect to main page if valid
            navigate("/auth/aamain"); // Replace "/" with your main page route
          } else {
            setAuth(null);
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Token validation error", error);
          setAuth(null);
          localStorage.removeItem("token");
        }
      }
    };

    // Only run the validation if the user is on the /auth page
    if (window.location.pathname === "/auth") {
      validateToken();
    }
  }, [navigate]); // Add navigate as dependency

  const login = async (email, password) => {
    try {
      const response = await api.post("/api/v1/auth/login", {
        email,
        password,
      });
      const token = response.data.token;

      localStorage.setItem("token", token);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("email", response.data.email);

      setAuth(token);
      return response;
    } catch (error) {
      console.error("Login error", error);
    }
  };

  const signup = async (email, password, name, otp) => {
    try {
      const response = await api.post("/api/v1/auth/signup", {
        email,
        password,
        name,
        otp,
      });
      const token = response.data.token;

      localStorage.setItem("token", token);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("email", response.data.email);

      setAuth(token);
      return response;
    } catch (error) {
      console.error("Signup error", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    setAuth(null);
  };

  const googleLogin = async (token) => {
    try {
      const response = await api.post("/api/v1/auth/google", { token });
      const authToken = response.data.token;

      localStorage.setItem("token", authToken);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("email", response.data.email);

      setAuth(authToken);
      return response;
    } catch (error) {
      console.error("Google login error", error);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, signup, logout, googleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
