import React, { useState, useEffect } from "react";
import { downloadFileS3, fetchAllCurrentJobs } from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Link,
  Tooltip,
  Grid,
} from "@mui/material";
import NoticePopup from "../../../common/NoticePopup";

interface Job {
  jobId: string;
  jobWanted: string;
  jobTitle: string;
  companyName: string;
  matchingScore: string;
}

interface CompanyCardProps {
  job: Job;
}

function prepareUrl(jobId: string): string {
  return `https://www.linkedin.com/jobs/collections/recommended/?currentJobId=${jobId}`;
}

const getRandomBrightColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = Math.floor(Math.random() * 156 + 100).toString(16);
    color += value.padStart(2, "0");
  }
  return color;
};

const CompanyCard: React.FC<CompanyCardProps> = ({ job }) => {
  const handleDownload = (jobId: string) => {
    downloadFileS3(jobId);
  };

  return (
    <Card
      sx={{
        marginBottom: "16px",
        borderRadius: "8px",
        color: "white",
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          <Link
            href={prepareUrl(job.jobId)}
            target="_blank"
            rel="noopener"
            sx={{
              color: getRandomBrightColor(),
              textDecoration: "none",
              "&:hover": {
                color: "#1abc9c",
                textDecoration: "underline",
              },
            }}
          >
            {job.companyName}
          </Link>
        </Typography>

        {/* Table-like Layout without table lines */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "100px 1fr",
            gap: "8px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Job Wanted:
          </Typography>
          <Typography variant="body1">{job.jobWanted}</Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Job Title:
          </Typography>
          <Typography variant="body1">{job.jobTitle}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Matching Score: {job.matchingScore}
            </Typography>
          </Box>
          <Button
            onClick={() => handleDownload(job.jobId)}
            sx={{
              backgroundColor: "#3498db",
              color: "white",
              "&:hover": {
                backgroundColor: "#2980b9",
              },
              marginTop: "8px",
            }}
          >
            Enhance Resume
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const AutoApplyStatistics: React.FC = () => {
  const constmessage =
    "Data fetched successfully. It may take up to 4 hours for changes to reflect in your account.";
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState("Fetching...");
  const [displayNotice, setDisplayNotice] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState(constmessage);
  const [jobs, setJobs] = useState<Job[]>([
    {
      jobId: "someId",
      jobWanted: "software engineer",
      jobTitle: "Web Development Internship in Delhi (Hybrid)",
      companyName: "company name",
      matchingScore: "80",
    },
    {
      jobId: "someIdd",
      jobWanted: "software engineer",
      jobTitle: "Web Development Internship in Delhi (Hybrid)",
      companyName: "company name",
      matchingScore: "80",
    },
  ]);

  useEffect(() => {
    setTimeout(() => {}, 1000);
  }, []);

  const handleFetchLatestJobs = async () => {
    setLoading(true);
    try {
      await fetchAllCurrentJobs();
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
      setDisplayNotice(true);
    }
  };

  return (
    <Box>
      <NoticePopup
        message={noticeMessage}
        displayNotice={displayNotice}
        setDisplayNotice={setDisplayNotice}
      />
      {loading && <LoaderCenter message={loadingMessage} />}
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "3%" }}
      >
        <Button
          onClick={handleFetchLatestJobs}
          sx={{
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
          }}
        >
          Fetch Latest Jobs
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
        {jobs.map((job) => (
          <CompanyCard key={job.jobId} job={job} />
        ))}
      </Box>
    </Box>
  );
};

export default AutoApplyStatistics;
