import React, { useState } from "react";
import {
  Box,
  Card,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoaderCenter from "../../common/LoaderCenter";
import SignUpGate from "./SignupGate";
import { otpCall } from "../../api/RapidData";
import GoogleLoginButton from "../../api/GoogleLoginButton";

interface LoginProps {
  setLoginOrSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Signup: React.FC<LoginProps> = ({ setLoginOrSignup }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFocus = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    clearError: boolean = true,
  ) => {
    if (clearError) setter("");
  };

  const handleSignup = () => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");

    if (!name) {
      setNameError("Name is required");
    }
    if (!email) {
      setEmailError("Email is required");
    }
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    }

    if (name && email && password.length >= 8 && password === confirmPassword) {
      setShowLoader(true);
      otpCall(email)
        .then((response: any) => {
          if (response.status === 200) {
            setShowOtpScreen(true);
          } else {
            setShowOtpScreen(false);
          }
          setShowLoader(false);
        })
        .catch(() => {
          setShowLoader(false);
          setShowOtpScreen(false);
        });
    }
  };

  return (
    <Card
      sx={{
        // backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent background

        bgcolor: "rgba(0, 0, 0, 0.6)", // Slight dark overlay
        backdropFilter: "blur(10px)", // Frosted glass effect
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
        color: "white", // Text color
        width: "80%", // Responsive width
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto", // Flexible height
        minHeight: "60vh",
        padding: isSmallScreen ? "16px" : "32px", // Padding for smaller screens
        borderRadius: "20px",
        margin: isSmallScreen ? "16px" : "auto", // Margin for smaller screens
      }}
    >
      {showLoader && <LoaderCenter message={"Loading"} />}
      {showOtpScreen && (
        <SignUpGate
          password={password}
          email={email}
          name={name}
          setShowLoader={setShowLoader}
          setShowOtpScreen={setShowOtpScreen}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={Boolean(nameError)}
          helperText={nameError}
          onFocus={() => handleFocus(setNameError)}
          InputLabelProps={{ style: { color: "white" } }} // White label text
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiFormHelperText-root": { color: "lightcoral" }, // Error text
          }}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(emailError)}
          helperText={emailError}
          onFocus={() => handleFocus(setEmailError)}
          InputLabelProps={{ style: { color: "white" } }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiFormHelperText-root": { color: "lightcoral" },
          }}
        />
        <TextField
          autoComplete="new-password"
          id="outlined-password"
          label="Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? (
                    <VisibilityOff sx={{ color: "white" }} />
                  ) : (
                    <Visibility sx={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiFormHelperText-root": { color: "lightcoral" },
          }}
          error={Boolean(passwordError)}
          helperText={passwordError}
          onFocus={() => handleFocus(setPasswordError)}
        />
        <TextField
          id="outlined-confirm-password"
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? (
                    <VisibilityOff sx={{ color: "white" }} />
                  ) : (
                    <Visibility sx={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiFormHelperText-root": { color: "lightcoral" },
          }}
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError}
          onFocus={() => handleFocus(setConfirmPasswordError)}
        />
        <Button
          variant="contained"
          onClick={handleSignup}
          fullWidth
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          Signup
        </Button>

        <Button
          onClick={() => setLoginOrSignup(true)}
          fullWidth
          sx={{
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          Login
        </Button>
        <GoogleLoginButton />
      </Box>
    </Card>
  );
};

export default Signup;
