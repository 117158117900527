import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const SB = ({ method, message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const vertical = "top"; // Default vertical position
  const horizontal = "center"; // Default horizontal position

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        severity={method === "pass" ? "success" : "error"}
        variant="filled"
        onClose={handleClose}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SB;
