import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";

const NoticePopup = ({ displayNotice, setDisplayNotice, message }) => {
  const handleClose = () => {
    setDisplayNotice(false); // Close the popup
  };

  return (
    <Dialog open={displayNotice} onClose={handleClose}>
      <Box sx={{ backgroundColor: "#121212" }}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message} {/* Message passed as a prop */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NoticePopup;
