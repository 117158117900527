import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, Container } from "@mui/material";

const Layout = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "100vh", // Ensures the container takes at least the full height of the viewport
      background: "linear-gradient(135deg, #1d2671 0%, #c33764 100%)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      overflowY: "auto", // Enables vertical scrolling if needed
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    }}
  >
    <Navbar />

    {/* Main content area, grows to take available space */}
    <Box
      sx={{
        flex: 1, // This allows the content to grow and take the remaining space
        paddingBottom: "100px", // To ensure there’s space for the footer if needed
      }}
    >
      <Container>{children}</Container>
    </Box>

    {/* Footer always at the bottom */}
    <Footer />
  </Box>
);

export default Layout;
