import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AutoApplyPricingPage = () => {
  const pricingOptions = [
    {
      title: "Free",
      price: "Free",
      features: [
        "2 apply per day MAX",
        "Use Your Own Resume, No Modification",
        "Low server bandwidth",
        "2 apply per 24 hr",
        "contains ads",
        "Low Success Rate",
      ],
    },
    {
      title: "Silver",
      price: "Rs 99/month",
      features: [
        "120 applies per day MAX",
        "Use Your Own Resume, No Modification",
        "High server bandwidth",
        "10 applies per 2 hr",
        "contains ads",
        "Low Success Rate",
      ],
    },
    {
      title: "Gold",
      price: "Rs 499/month",
      features: [
        "240 apply per day MAX",
        "AI modified resume before applying",
        "Very High server bandwidth",
        "20 apply per 1 hr",
        "no ads",
        "High Success Rate",
      ],
    },
  ];

  return (
    <Grid container spacing={4} justifyContent="center" sx={{ padding: 4 }}>
      {pricingOptions.map((option, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              minHeight: 300,
              boxShadow: index === 1 ? 5 : 1,
              border: index === 1 ? "2px solid #9C27B0" : "1px solid #ccc", // Purple border for highlighted card
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Slight dark overlay
              backdropFilter: "blur(10px)", // Frosted glass effect
              color: "white", // White font color
              borderRadius: "15px", // Rounded corners
              "&:hover": {
                transform: "scale(1.05)",
                transition: "transform 0.3s ease-in-out",
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {option.title}
              </Typography>
              <Typography variant="h6" gutterBottom sx={{ color: "white" }}>
                {option.price}
              </Typography>

              <List>
                {option.features.map((feature, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature}
                      sx={{ color: "white" }} // White text for features
                    />
                  </ListItem>
                ))}
              </List>

              <Button
                variant={index === 1 ? "contained" : "outlined"}
                color="primary"
                fullWidth
                sx={{
                  marginTop: 3,
                  padding: 1,
                  fontWeight: "bold",
                  borderRadius: 3,
                  color: "white", // Button text color
                  borderColor: "#9C27B3", // Purple border color
                  "&:hover": {
                    borderColor: "#9C27B0", // Button hover border color
                    backgroundColor: "#9C27B0", // Button hover background color
                  },
                }}
              >
                {index === 1 ? "Go Premium" : "Choose Free"}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AutoApplyPricingPage;
