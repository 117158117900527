import React from "react";
import { Container, Typography, Card, CardContent, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AboutPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",

        overflowX: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        overflowY: "auto",
        padding: "50px",
      }}
    >
      <Container
        sx={{
          maxWidth: "sm",
          padding: theme.spacing(4),
          bgcolor: "rgba(0, 0, 0, 0.6)", // Slight dark overlay
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[10],
          backdropFilter: "blur(12px)", // Glass effect
          marginX: theme.spacing(4), // Horizontal margin from both sides
        }}
      >
        {/* Who We Are Section */}
        <Card sx={{ mb: 4, bgcolor: "transparent", boxShadow: "none" }}>
          <CardContent>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{ color: theme.palette.common.white }}
            >
              Who We Are?
            </Typography>
            <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
              We are a team of passionate individuals with a shared vision to
              make the world a better place. Our team consists of great minds
              from diverse fields, working collaboratively to create innovative
              solutions to global challenges. We believe that through
              creativity, perseverance, and a strong sense of responsibility, we
              can drive positive change. By leveraging technology and
              human-centric approaches, we continuously strive to empower
              communities, improve lives, and shape a brighter, sustainable
              future for generations to come.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default AboutPage;
