import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import AutoApplySettings from "./AutoApplySettings";
import AutoApplyStatistics from "./AutoApplyStatistics";
import AutoApplyTrigger from "./AutoApplyTrigger";
import AutoApplyPricingPage from "./AutoApplyPricingPage";

const Navbar = () => {
  // State to manage which component to display
  const [activeComponent, setActiveComponent] = useState("trigger");

  // Function to render the appropriate component
  const renderComponent = () => {
    switch (activeComponent) {
      case "trigger":
        return <AutoApplyTrigger />;
      case "statistics":
        return <AutoApplyStatistics />;
      case "settings":
        return <AutoApplySettings />;
      case "pricing":
        return <AutoApplyPricingPage />;
      default:
        return <AutoApplyTrigger />;
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        marginTop: "0.5%",
      }}
    >
      <AppBar
        position="static"
        sx={{
          boxShadow: "0px 8px 20px rgba(0,0,0,0.7)",
          borderRadius: "15px",
          color: "white",
          bgcolor: "rgba(0, 0, 0, 0.4)", // Slight dark overlay
        }}
      >
        <Toolbar>
          <Button color="inherit" onClick={() => setActiveComponent("trigger")}>
            Trigger
          </Button>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("statistics")}
          >
            Statistics
          </Button>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("settings")}
          >
            Settings
          </Button>
          <Button color="inherit" onClick={() => setActiveComponent("pricing")}>
            Pricing
          </Button>
        </Toolbar>
      </AppBar>

      {/* Render the selected component */}
      <Box>{renderComponent()}</Box>
    </Box>
  );
};

export default Navbar;
