import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
  Slider,
  SelectChangeEvent,
} from "@mui/material";
import { save_settings, retrieve_settings } from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import SB from "../../../common/Snackbar";

interface FormValues {
  jobWanted: string;
  jobLevel: string;
  matchingScore: number;
}

const AutoApplyMainPage: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    jobLevel: "",
    jobWanted: "",
    matchingScore: 90,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [sbMessage, setSbMessage] = useState<string>("");
  const [showSb, setShowSb] = useState<boolean>(false);

  useEffect(() => {
    setShowLoader(true);
    const getSettings = async () => {
      try {
        const response: any = await retrieve_settings();
        if (response.status === 200) {
          const { jobWanted, jobLevel, matchingScore } = response.data;

          setFormValues({
            jobWanted: jobWanted,
            jobLevel: jobLevel,
            matchingScore: matchingScore || 90,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setShowLoader(false);
      }
    };
    getSettings();
  }, []);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    setFormValues({ ...formValues, matchingScore: value as number });
  };

  const handleExperienceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedExperience = event.target.name;
    setFormValues({ ...formValues, jobLevel: selectedExperience });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setShowSb(false);
    setIsEditing(false);
    setShowLoader(true);
    const linkedinRequest = {
      jobWanted: formValues?.jobWanted,
      jobLevel: formValues?.jobLevel,
      matchingScore: formValues?.matchingScore,
    };

    save_settings(linkedinRequest)
      .then((response: any) => {
        if (response.status == 200) {
          setSbMessage("Saved Successfully");
        } else {
          setSbMessage("Something Went Wrong");
        }
      })
      .catch((error) => {
        setSbMessage("Something Went Wrong");
      })
      .finally(() => {
        setShowLoader(false);
        setShowSb(true);
      });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
      {showSb && <SB message={"Saved Successfully"} method={"pass"}></SB>}
      <Box
        sx={{
          maxWidth: 500,
          margin: "0px 20px",
          padding: 4,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.7)",
          borderRadius: 2,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          color: "white",
        }}
      >
        {showLoader && <LoaderCenter message="loading..." />}
        <Typography variant="h4" gutterBottom align="center">
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Matching Score: {formValues.matchingScore}%
            </Typography>
            <Slider
              value={formValues.matchingScore}
              onChange={handleSliderChange}
              aria-labelledby="matching-score-slider"
              step={1}
              marks
              min={0}
              max={100}
              disabled={!isEditing}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Job Wanted"
              name="jobWanted"
              value={formValues.jobWanted}
              onChange={handleTextFieldChange}
              disabled={!isEditing}
              sx={{
                color: "white",
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Experience:
            </Typography>
            <FormGroup>
              {[
                "Internship",
                "Entry level",
                "Associate",
                "Mid-Senior level",
                "Director",
                "Executive",
              ].map((exp) => (
                <FormControlLabel
                  key={exp}
                  control={
                    <Checkbox
                      checked={formValues.jobLevel === exp}
                      onChange={handleExperienceChange}
                      name={exp}
                      disabled={!isEditing}
                    />
                  }
                  label={exp}
                  sx={{ color: "white" }}
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditClick}
              disabled={isEditing}
              sx={{
                backgroundColor: "#9C27B0",
                "&:hover": {
                  backgroundColor: "#7B1FA2",
                },
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSaveClick}
              disabled={!isEditing}
              sx={{
                backgroundColor: "#9C27B0",
                "&:hover": {
                  backgroundColor: "#7B1FA2",
                },
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AutoApplyMainPage;
