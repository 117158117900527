import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
} from "@mui/material";
import {
  saveFile,
  updateActiveStatus,
  retrieveAutoApplyUserData,
} from "../../../api/RapidData";
import LoaderCenter from "../../../common/LoaderCenter";
import TypingGreetings from "../../../common/TypingGreetings";
import NoticePopup from "../../../common/NoticePopup";

const AutoApplyTrigger: React.FC = () => {
  const [dynamicMargin, setDynamicMargin] = useState<Map<string, string>>(
    new Map(),
  );
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [latexFile, setLatexFile] = useState<File | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("No file chosen");
  const [optButton, setOptButton] = useState("I Am In");
  const [displayNotice, setDisplayNotice] = useState(false);
  const [noticeMessage, setNoticeMessage] = useState("");
  const [jobLevel, setJobLevel] = useState<string | null>(null);
  const [jobWanted, setJobWanted] = useState<string | null>(null);

  useEffect(() => {
    const updateMarginTop = () => {
      if (window.innerWidth <= 768) {
        setDynamicMargin(
          new Map([
            ["welcomeTextRight", "0%"],
            ["resumeUploadMargin", "50% 0 0 0"],
          ]),
        );
      } else {
        setDynamicMargin(
          new Map([
            ["welcomeTextRight", "20%"],
            ["resumeUploadMargin", "20% 0 0 0"],
          ]),
        );
      }
    };
    updateMarginTop();

    // Add event listener for window resize
    window.addEventListener("resize", updateMarginTop);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateMarginTop);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response: any = await retrieveAutoApplyUserData();
        if (response.status === 200) {
          const { userResume, isActive, jobWanted, jobLevel } = response.data;
          setJobLevel(jobLevel);
          setJobWanted(jobWanted);

          if (!jobLevel || !jobWanted) {
            setNoticeMessage("Please Complete The Settings, Before Opt In");
            setDisplayNotice(true);
          }

          setOptButton(isActive ? "I Am Out" : "I Am In");
          if (userResume) {
            setLatexFile(new File([], userResume));
            setFileName(userResume);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(loading || !latexFile || !jobLevel || !jobWanted);
  }, [loading, latexFile, jobLevel, jobWanted]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setLatexFile(file);
      setFileName(file.name);

      setLoading(true);
      setError(null);
      try {
        await saveFile(file.name, file, "user-resume");
      } catch {
        setError("Failed to upload file. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCustomButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  const handleOptButton = async () => {
    setLoading(true);
    try {
      await updateActiveStatus(optButton === "I Am In");
      setNoticeMessage("It may take up to four hours to reflect your changes");
      setDisplayNotice(true);

      setOptButton(optButton === "I Am In" ? "I Am Out" : "I Am In");
    } catch (err) {
      setNoticeMessage("Oops something went wrong");
      setDisplayNotice(true);
      console.error("Error updating status:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <NoticePopup
        message={noticeMessage}
        displayNotice={displayNotice}
        setDisplayNotice={setDisplayNotice}
      />
      <Box
        sx={{
          position: "absolute",
          top: "14%",
          right: dynamicMargin.get("welcomeTextRight"),
          padding: 2,
          zIndex: 1000,
        }}
      >
        <TypingGreetings
          duration={15000}
          text={`Hello ${localStorage.getItem("name") || ""}!`}
        />
      </Box>
      <Grid
        container
        justifyContent="center"
        sx={{ margin: dynamicMargin.get("resumeUploadMargin") }}
      >
        {loading && <LoaderCenter message={loadingMessage} />}
        <Card
          sx={{
            maxWidth: 600,
            padding: 3,
            boxShadow: "0px 8px 20px rgba(0,0,0,0.7)",
            borderRadius: "15px",
            bgcolor: "rgba(0, 0, 0, 0.4)",
            color: "white",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Upload Your Resume
            </Typography>
            <input
              type="file"
              accept=".pdf"
              id="file-input"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              onClick={handleCustomButtonClick}
              sx={{
                marginBottom: 2,
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "18px",
                textTransform: "none",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",

                "&:active": { backgroundColor: "#388e3c" },
              }}
            >
              Choose File
            </Button>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              {fileName}
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleOptButton}
              variant="contained"
              disabled={isButtonDisabled}
              sx={{
                backgroundColor:
                  optButton === "I Am Out" ? "#f44336" : "#4caf50",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "18px",
                textTransform: "none",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor:
                    optButton === "I Am Out" ? "#e57373" : "#66bb6a",
                },
                "&:active": {
                  backgroundColor:
                    optButton === "I Am Out" ? "#d32f2f" : "#388e3c",
                },
              }}
            >
              {optButton}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Box>
  );
};

export default AutoApplyTrigger;
