import React, { useState, useContext } from "react";
import { Box, Card, TextField, Button, Typography } from "@mui/material";
import { AuthContext } from "../../api/AuthProvider"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
import LoaderCenter from "../../common/LoaderCenter";
import GoogleLoginButton from "../../api/GoogleLoginButton";

const useStyles = {
  // backgroundColor: "rgba(255, 255, 255, 0.6)", // Transparent background

  bgcolor: "rgba(0, 0, 0, 0.6)", // Slight dark overlay
  backdropFilter: "blur(10px)", // Frosted glass effect
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", // Subtle shadow
  width: "80%", // Responsive width
  display: "flex",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  height: { xs: "auto", sm: "60vh" }, // Adjust height for smaller screens
  borderRadius: "20px",
  padding: { xs: "20px", sm: "40px" }, // Padding for small screens
  margin: { xs: "20px 0", sm: "auto" }, // Margin to add space around on small screens
  color: "white", // Text color
};

interface LoginProps {
  setLoginOrSignup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({ setLoginOrSignup }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const handleLogin = async () => {
    try {
      login(email, password)
        .then((response: any) => {
          if (response.status === 200) {
            navigate("/auth/aamain");
          } else {
            setShowLoader(false);
          }
        })
        .catch((error: any) => {
          setShowLoader(false);
        });
    } catch (error) {
      setShowLoader(false);
    }
  };

  return (
    <Card sx={useStyles}>
      {showLoader && <LoaderCenter message={"Loading"} />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <TextField
          inputProps={{
            autoComplete: "new-password",
          }}
          id="outlined-basic"
          label="Email"
          variant="outlined"
          sx={{
            width: "100%", // Responsive width
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiInputLabel-root": { color: "white" }, // White label text
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          inputProps={{
            autoComplete: "new-password",
          }}
          id="outlined-basic"
          label="Password"
          variant="outlined"
          sx={{
            width: "100%", // Responsive width
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
            "& input": { color: "white" },
            "& .MuiInputLabel-root": { color: "white" }, // White label text
          }}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={handleLogin}
          fullWidth
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          Login
        </Button>

        <Button
          onClick={() => setLoginOrSignup(false)}
          fullWidth
          sx={{
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          SignUp
        </Button>
        <Button
          onClick={() => setLoginOrSignup(false)}
          sx={{
            width: { xs: "100%", sm: "80%" },
            color: "white",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          Forget your password?
        </Button>
        <GoogleLoginButton />
      </Box>
    </Card>
  );
};

export default Login;
